/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Menu from "./Menu";
import Content from "./Content";
import Minds from "./Minds";
import LetterOne from "./LetterOne";
import LetterTwo from "./LetterTwo";
import LetterThree from "./LetterThree";
import LetterFour from "./LetterFour";
import { useState } from "react";
import Splash from "./Splash";


function App() {
  const [access, setAccess] = useState(false);
  const [page, setPage] = useState("MINDS");

  console.log("🙏🙏🙏");

  let currentPage;
  if (page === "MINDS") {
    currentPage = <Minds />;
  } else if (page === "LETTERONE") {
    currentPage = <LetterOne />;
  } else if (page === "LETTERTWO") {
    currentPage = <LetterTwo />;
  } else if (page === "LETTERTHREE") {
    currentPage = <LetterThree />;
  } else if (page === "LETTERFOUR") {
    currentPage = <LetterFour />;
  }

  return (
    <div>
      {access === false ? (
        <Splash setAccess={setAccess} />
      ) : (
        <div>
          <Menu page={page} setPage={setPage} />
          <Content>{currentPage}</Content>
        </div>
      )}
    </div>
  );
}

export default App;
