/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Redacted from "./Redacted";
import MISC from "./assets/images/pages.png";
import LETTERS from "./assets/images/letters.png";
import LOVE from "./assets/images/love.png";
import BG from "./assets/images/background-letter-2.png";

function LetterTwo() {
  return (
    <div>
      <div
        css={css`
          float: left;
          width: 100%;
          height: 100%;
          background: #fffefd;
          padding-bottom: 60px;
          p {
            margin-bottom: 40px;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: url(${BG});
          `}
        />
        <div
          css={css`
            margin-left: auto;
            margin-right: auto;
            width: 712px;
            font-family: IBM Plex Sans;
            font-color: #040030;
            @media (max-width: 980px) {
              padding-left: 130px;
              box-sizing: border-box;
              width: calc(100% - 72px);
            }
            @media (max-width: 480px) {
              padding-left: 0px;
            }
          `}
        >
          <div
            css={css`
              float: left;
              width: 100%;
              margin-top: 80px;
              text-align: right;
              font-style: italic;
              font-size: 20px;
              line-height: 30px;
            `}
          >
            Sunday, 31 Jan 2021
          </div>

          <div
            css={css`
              float: left;
              width: 100%;
              margin-top: 80px;
              font-size: 20px;
              line-height: 30px;
              font-family: Averia Serif Libre;
              font-size: 25px;
              line-height: 35px;
            `}
          >
            Dear <Redacted width={100} height="22" background="#cda6a7" />,
            <p>
              For days we have been working hard to get my mother to the
              Netherlands. She lived with my father on a remote farm in
              Battambang, North West of Cambodia. My father fell ill and before
              she knew it he passed away. She had arranged the funeral/cremation
              ceremony and was surrounded by strangers and we, her children,
              watched our father cremate through a shitty facebook video, it was
              very cruel.
            </p>
            <div
              css={css`
                float: left;
                width: 100%;
                font-size: 20px;
                line-height: 30px;
                font-family: IBM Plex Sans;
              `}
            >
              <p>
                My mother is totally dependent on my dad - he managed
                everything, built everything and organised everything, my dad is
                the brain, the muscle and the reason.
              </p>
              <p>
                She has a phone, but she can only access Facebook messenger, and
                does not know her password. She does not know how to text, but
                she can add credit to her phone. She is hard to reach when you
                call her - she misses phone calls and we don’t know why. Her
                phone is shitty and broken, if it drops below a certain
                percentage, it won’t even turn on, even after a full recharge.
                <p>
                  My mother is close to illiterate, she can speak Khmer
                  fluently, Dutch in her own way and has no ability to speak
                  English. In addition to her poor technical skills, she finds
                  it really hard to make good judgments and decisions.
                </p>
                <div
                  css={css`
                    float: left;
                    width: 100%;
                    img {
                      width: 100%;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      max-width: 712px;
                      margin-top: 40px;
                      margin-bottom: 40px;
                    }
                  `}
                >
                  <img src={MISC} />
                </div>
                <p>
                  She has only had four years of primary school education, she
                  was abandoned by her father when she was a toddler, her mother
                  disappeared before the war, she endured the Khmer Rouge as a
                  child, and spend four years as a refugee in the Thai Refugee
                  camps in the eighties as a teenager where she was in an
                  arranged marriage with my father.
                </p>
                <p>
                  In the camps, she birthed my brother. At eighteen she arrived
                  in the Netherlands with my dad and my brother, and three years
                  later I was born. All these events, plus life in the
                  Netherlands, which were full of day and night shifts in shitty
                  factories, have made it so that my mother is distrustful of
                  the world, is hard to reason with and is confused and panicky
                  all the time. She also suffers from diabetes and hepatitis b
                  (which we only recently discovered) and she gets her
                  medication from Thailand. This has been difficult since the
                  borders have closed, so she orders them.
                </p>
                <p>
                  Before her arrival in the capital Phnom Penh, where she is
                  right now, she arranged the three day funeral ceremony, and
                  after the ceremony she did not feel safe in her house on the
                  farm. She could not access her money, since it was on my
                  fathers name, but she had to pay people back.
                </p>
                <p>
                  <div
                    css={css`
                      float: left;
                      width: 100%;
                      background: #cda6a7;
                      height: 130px;
                      margin-bottom: 0px;
                    `}
                  ></div>
                  By the third day she was able access her money thankfully.
                  <br />
                  <br />
                  <br />
                </p>
                <p
                  css={css`
                    :first-letter {
                      color: #040030;
                      float: left;
                      font-family: IM Fell Double Pica;
                      font-size: 58px;
                      line-height: 56px;
                      padding-top: 4px;
                      padding-right: 8px;
                      padding-left: 3px;
                    }
                  `}
                >
                  We set out a beautiful calm plan with hotels in Battambang and
                  Phnom Penh but suddenly she decided to take the bus to Phnom
                  Penh and forget all the steps. This was because a few
                  acquaintances appeared out of nowhere, and wanted to give her
                  a ride to the bus stop. Fine, we thought. Then she forgot her
                  medicine at the bus stop.{" "}
                  <Redacted width="280" background="#cda6a7" />. At this point,
                  I did not know what to believe anymore. After a while she did
                  get her medicine and was on her way to the capital.
                </p>
                <p>
                  Finally she had arrived in Phnom Penh, where I have contacts
                  from my Cambodia days, so she was picked up by a friend from
                  the Khmer Dutch community, who guided her to the hotel we had
                  booked. Here she visited the temple to pay respect to my
                  father (it is the last day of the seven day Khmer buddhist
                  ceremony) and went back to the hotel, where she started
                  panicking about the land.
                </p>
                <p>
                  She said she wanted to leave as soon as possible{" "}
                  <Redacted width="260" background="#cda6a7" />{" "}
                  <Redacted width="200" background="#cda6a7" />. I prepared a
                  concise list of things for her to take care of before she
                  leaves for the Netherlands, which I had researched over and
                  over again. I had another friend meet her at the lobby, who
                  brought some fruits, and tried to suss out the situation and
                  give her some reason. My friend said that indeed she is
                  stubborn and panicky. We thought that if we can get her to
                  calm down and get her to stick to the list of things to do,
                  then we will be fine.
                </p>
                <p>
                  We have been researching and communicating back and forth with
                  anyone who can help us. My mother is a moving target and new
                  surprises meet us every day.
                </p>
                <p>
                  <b>
                    <u>For instance:</u>
                  </b>
                </p>
                <ul>
                  <li>
                    We considered my brother going there, but he would have to
                    go into hotel quarantine and put down a deposit of 2000USD,
                    and for him to go and then come backwasn’t a quick and easy
                    solution because we would rather have her to come back asap
                    and not spend so much on plane tickets (and let alone
                    risking his health and safety). We had so many people
                    consult us on this choice but all three of us decided it’s
                    best if she can come to Holland by herself.
                  </li>{" "}
                  <br />
                  <li>
                    She had indicated that the transfer will be tricky and if I
                    can arrange some sort of meeting service for the disabled on
                    transit in Singapore, but as I looked them up, all these
                    services were cancelled as a coronavirus protective measure.
                    Though they said they will arrange very clear signage.
                  </li>
                  <br />
                  <li>
                    We called several institutions, such as the gemeente
                    (muncipality), to find out if she is able to enter the
                    Netherlands, as she has been away for five years, and they
                    said yes. We contacted organisations locally if they could
                    help us with relocating her when she gets here and they said
                    there would be assistance.
                  </li>
                  <br />
                  <li>
                    We researched whether she can take the ashes with her on the
                    plane.
                  </li>
                </ul>
                <p>
                  <div
                    css={css`
                      float: left;
                      width: 100%;
                      img {
                        width: 100%;
                        display: block;
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 712px;
                        margin-top: 40px;
                        margin-bottom: 60px;
                      }
                    `}
                  >
                    <img src={LETTERS} />
                  </div>

                  <p
                    css={css`
                      :first-letter {
                        color: #040030;
                        float: left;
                        font-family: IM Fell Double Pica;
                        font-size: 58px;
                        line-height: 56px;
                        padding-top: 4px;
                        padding-right: 8px;
                        padding-left: 3px;
                      }
                    `}
                  >
                    Finally we researched the situation with corona tests. A
                    friend explained to us Cambodia’s corona test procedure. She
                    said there’s a landline you can call (115) where they will
                    give you custom advice on which tests you need and when,
                    according to the country you are travelling to. There’s only
                    one clinic which has the right to issue the certificates for
                    travel.
                  </p>
                  <p>
                    We were convinced if she rings this number with one of my
                    local friends and listen carefully to their instructions
                    together - everything will be fine. As we were trying to
                    figure out where she could get the death certificate
                    document translated, apart from the foreign ministry, we
                    emailed the Dutch consulate. We asked him about this ‘new
                    second rapid test’ (4 hour test) rule the Dutch have been
                    talking about, and whether she can get it at the airport;
                    and if it's true she can get the death certificate of my
                    father translated at the foreign ministry.
                  </p>
                  <p>
                    We got an email back saying: "I am sorry, Cambodia does not
                    have any ‘second rapid test’ centres. This means she will
                    not be allowed to board the plane." He suggested to fly to a
                    different country which doesn’t have this corona rapid 4
                    hour test requirement, get tested there and try to get a
                    direct plane to Holland (being aware of quarantines of each
                    country) or simply wait out until the requirement goes away.
                    Our hearts broke.
                  </p>
                </p>
                <p>
                  My mother each day is getting more crazy, panicky, it is like
                  dealing with someone who is ninety, slightly dementia, prone
                  to panic attacks and with underlying health conditions, and we
                  don't know how to aid her.
                </p>
                <div
                  css={css`
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: url(${BG});
                  `}
                />
                <p>
                  <div
                    css={css`
                      float: left;
                      width: 100%;
                      background: #cda6a7;
                      height: 173px;
                      margin-bottom: 40px;
                    `}
                  ></div>
                </p>
                <p
                  css={css`
                    font-size: 48px;
                    line-height: 43px;
                    font-family: Averia Serif Libre;
                  `}
                >
                  “Because your mum is not a diplomat or an urgent worker she
                  cannot come to Holland if she doesn’t take this second test.”
                </p>
                <p>
                  At the moment it is only this second 4 hour rapid test, which
                  only has been implemented on the 23 januari 2021, as an
                  absolute requirement, has rendered it nearly impossible for
                  someone like my mother to come here.
                </p>
                <p>
                  <div
                    css={css`
                      float: left;
                      width: 100%;
                      background: #cda6a7;
                      height: 90px;
                      margin-bottom: 0px;
                    `}
                  ></div>
                  trying to figure things out at the moment. If my mother was in
                  the position to not panic and accept the situation (my friend
                  who came by tried to instill this in her) we would have had
                  more lee way. Now it feels like every day counts. We also
                  don’t know exactly what’s going on with her medically.
                </p>
              </p>
              <p></p>

              <br />
              <p
                css={css`
                  :first-letter {
                    color: #040030;
                    float: left;
                    font-family: IM Fell Double Pica;
                    font-size: 58px;
                    line-height: 56px;
                    padding-top: 4px;
                    padding-right: 8px;
                    padding-left: 3px;
                  }
                `}
              >
                On the father side of things: We have finished the seven day
                ceremony for my father of which we pray for him daily so that he
                can depart to his next ‘life’. I am sad I cannot mourn my
                fathers passing properly, but at the moment I am okay. The last
                few weeks he did not want to speak to me and I took it as his
                way of saying he is battling something big. Little did I know it
                was his actual life, it was my biggest fear come true. My father
                IS my biggest inspiration, my hero. He has always encouraged me
                to live a meaningful, dignified and rich life. My relationship
                with him was the most intimate I ever had.
              </p>
              <p>
                <div
                  css={css`
                    float: left;
                    width: 100%;
                    background: #cda6a7;
                    height: 100px;
                    margin-bottom: 0px;
                  `}
                ></div>
                I wish I didn’t need some advice but I do. Please help me. I
                don’t know how you could.
              </p>
              <p></p>
              <p>
                Love.
                <br />
                <br />
                <img src={LOVE}></img>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default LetterTwo;
