/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Plus from "./assets/images/plus.png";
import Minus from "./assets/images/minus.png";
import Background from "./assets/images/menu.png";
import One from "./assets/images/one.png";
import Two from "./assets/images/two.png";
import Three from "./assets/images/three.png";

function Menu({ setPage }) {
  function handlePage(page) {
    window.scrollTo(0, 0);
    setPage(page);
  }

  return (
    <div
      css={css`
        position: fixed;
        top: calc(50% - 137.5px);
        left: 36px;
        width: 95.5px;
        z-index: 1;
        img {
          cursor: pointer;
          :hover {
            transform: scale(0.95);
          }
        }
      `}
    >
      <div
        css={css`
          float: left;
          width: 100%;
          padding-left: 25px;
          box-sizing: border-box;
        `}
      >
        <img width={36} src={Plus} onClick={() => handlePage("MINDS")} />
      </div>
      <div
        css={css`
          float: left;
          width: 100%;
          height: 185px;
          background: url(${Background});
          background-size: contain;
          padding-top: 42px;
          box-sizing: border-box;
        `}
      >
        <div
          css={css`
            float: left;
            width: 100%;
            text-align: center;
          `}
        >
          <img width={47} src={One} onClick={() => handlePage("LETTERONE")} />
        </div>
        <div
          css={css`
            float: left;
            width: 100%;
            text-align: center;
          `}
        >
          <img width={47} src={Two} onClick={() => handlePage("LETTERTWO")} />
        </div>{" "}
        <div
          css={css`
            float: left;
            width: 100%;
            text-align: center;
          `}
        >
          <img
            width={47}
            src={Three}
            onClick={() => handlePage("LETTERTHREE")}
          />
        </div>
      </div>
      <div
        css={css`
          float: left;
          width: 100%;
          text-align: center;
        `}
      >
        <img width={34} src={Minus} onClick={() => handlePage("LETTERFOUR")} />
      </div>
    </div>
  );
}

export default Menu;
