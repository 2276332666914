/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Redacted from "./Redacted";
import ROOFTOP from "./assets/images/rooftop.png";
import BIRDS from "./assets/images/birds.png";
import ZONDER from "./assets/images/zonder.png";
import SUNNY from "./assets/images/sunny.png";

function LetterThree() {
  /* 🙏🙏🙏 */

  return (
    <div>
      <div
        css={css`
          float: left;
          width: 100%;
          height: 100%;
          background: #000c4d;
          padding-bottom: 60px;
          p {
            margin-bottom: 60px;
          }
        `}
      >
        <div
          css={css`
            margin-left: auto;
            margin-right: auto;
            width: 712px;
            font-family: IBM Plex Sans;
            color: white;
            font-size: 20px;
            line-height: 30px;
            @media (max-width: 980px) {
              padding-left: 130px;
              box-sizing: border-box;
              width: calc(100% - 72px);
            }
            @media (max-width: 480px) {
              padding-left: 0px;
            }
          `}
        >
          <div
            css={css`
              float: left;
              width: 100%;
              margin-top: 80px;
              text-align: right;
              font-style: italic;
              font-size: 20px;
              line-height: 30px;
            `}
          >
            Thursday, 4 Feb 2021
          </div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-top: 80px;
              font-size: 20px;
              line-height: 30px;
              font-family: Averia Serif Libre;
              font-size: 25px;
              line-height: 35px;
            `}
          >
            Dear <Redacted background="#c96c6d" height="22" />,
            <p>
              I don't know what caused the passing of my father. I whatsapp with{" "}
              <Redacted width={180} background="#c96c6d" /> on a daily basis and
              he mentioned that my mother casually mentioned to him that my
              father might have died of an intracranial hemorrhage. A stroke.
            </p>
            <div
              css={css`
                float: left;
                width: 100%;
                font-size: 20px;
                line-height: 30px;
                font-family: IBM Plex Sans;
              `}
            >
              <p>
                Yesterday, my mother went out. Her tuk tuk driver is the father
                of a friend of mine. She hires him per day, he drives her
                everywhere. My mother had to fix the battery on her phone (very
                crucial for our communication) and he asked her whether there
                were any other spots she would like to visit before picking up
                the phone. She said she had no idea. The tuktuk driver (we call
                him <Redacted width={80} background="#c96c6d" />) said "you're
                in your room the entire time, here, let me take you out", and
                took her to the riverside.
              </p>
              <p>
                She suddenly realised she wanted to see a fortune teller. She
                asked the fortune teller: if she would board a plane to Holland,
                would she be safe from catching corona? The fortune teller
                replied that she would be and that the Dutch borders would open
                up around the 20th of April.
              </p>
              <div
                css={css`
                  float: left;
                  width: 100%;
                  text-align: center;
                `}
              >
                <img width={292} src={ZONDER}></img>
                <br />
                <br />
                <br />
              </div>
              <p>
                The Dutch government hasn't mentioned any dates officially on
                when the second 4 hour rapid test requirement will go, but on
                the Ministry of Foreign affairs' Twitter timeline, I have seen
                the date 31st of March mentioned here and there. I interpret it
                as a govt-internally-agreed date to revise this second rapid
                test and that it was not part of their official PR. Who knows?
              </p>
              <p>
                My mother believes the fortune teller as she was able to recall
                intricate details of my mother's life and my fathers passing.
                One such detail was that my mother had been praying without
                incense. She said:
              </p>
              <p
                css={css`
                  font-size: 30px;
                  line-height: 40px;
                  font-family: Patua One;
                `}
              >
                "You haven't held the lower part of the incense for a long
                time!"
              </p>
              <p>
                She mentioned my father is hungry, and that with each meal, my
                mother should invite him for dinner too. She said his death was
                an accident. She also said that my mother needs to make an
                offering because he has no money - and it's Chinese New Year
                soon (apparently it relates). So my mother paid the fortune
                teller, who then hired an MC, arranged some prayer-related
                objects and performed a ritual for half an hour. She burnt some
                bills.
              </p>
              <div
                css={css`
                  float: left;
                  width: 100%;
                  background: #c96c6d;
                  height: 120px;
                  margin-bottom: 40px;
                `}
              ></div>
              No autopsy was made.
              <div
                css={css`
                  float: left;
                  width: 100%;
                  background: #c96c6d;
                  height: 80px;
                  margin-bottom: 40px;
                `}
              ></div>
              <div>
                <div>
                  <div
                    css={css`
                      margin-left: auto;
                      margin-right: auto;
                      width: 712px;
                      font-family: IBM Plex Sans;
                      font-size: 20px;
                      line-height: 30px;
                      color: white;
                      @media (max-width: 980px) {
                        width: calc(100% - 72px);
                      }
                    `}
                  >
                    <p
                      css={css`
                        font-size: 30px;
                        line-height: 40px;
                        font-family: Averia Serif Libre;
                      `}
                    >
                      'My father passed away and my mother is now left alone in
                      Cambodia with no family or friends, is in need of urgent
                      medical and mental care, incapable of taking care of
                      herself and we fear greatly for her well being.’
                      <br />
                      <br />
                    </p>
                  </div>{" "}
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
        <div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-bottom: 40px;
              img {
                width: 100%;
              }
            `}
          >
            <img src={ROOFTOP} />
          </div>
          <div
            css={css`
              margin-left: auto;
              margin-right: auto;
              width: 712px;
              font-family: IBM Plex Sans;
              font-size: 20px;
              line-height: 30px;
              color: white;
              @media (max-width: 980px) {
                padding-left: 130px;
                box-sizing: border-box;
                width: calc(100% - 72px);
              }
              @media (max-width: 480px) {
                padding-left: 0px;
              }
            `}
          ></div>
          <div
            css={css`
              float: left;
              width: 100%;
              margin-top: 40px;
            `}
          >
            <div
              css={css`
                margin-left: auto;
                margin-right: auto;
                width: 712px;
                color: white;
                font-size: 20px;
                line-height: 30px;
                font-family: IBM Plex Sans;
                @media (max-width: 980px) {
                  padding-left: 130px;
                  box-sizing: border-box;
                  width: calc(100% - 72px);
                }
                @media (max-width: 480px) {
                  padding-left: 0px;
                }
              `}
            >
              <p
                css={css`
                  :first-letter {
                    color: white;
                    float: left;
                    font-family: IM Fell Double Pica;
                    font-size: 58px;
                    line-height: 56px;
                    padding-top: 4px;
                    padding-right: 8px;
                    padding-left: 3px;
                  }
                `}
              >
                Monday night was the scariest night of our lives. We checked her
                in a hotel in Phnom Penh (fancy because Cambodia has no tourists
                at the moment) and I sent a good friend (
                <Redacted width={70} background="#c96c6d" />) of mine to come
                and visit her.
              </p>
              <p>
                He said to me that 'she looked fine, just a little confused in
                her eyes' and at this time, she felt haunted by the idea that
                people were out there to get her and her land. My brother and I
                urged her to come to Phnom Penh and created an itenary for her,
                but she abandoned it, but thankfully she did make her way there.
              </p>
              <p>
                On Monday <Redacted width={70} background="#c96c6d" /> messaged
                me, asking if my mum was going to go back to Battambang (back on
                the eight hour bus-ride back to the remote farm) and I said..
                "no? How come you are asking?" He said that{" "}
                <Redacted width={80} background="#c96c6d" /> said that my mum
                asked him to bring her to the bus station at 6am the next day. I
                was shook and said I'd call my mum to clarify.
              </p>
              <p>
                She didn't pick up. She turned off her phone. She never does
                that. I called the hotel, but they didn't dare to knock on her
                door. My brother and I kept calling and calling and sometimes it
                rang. At times someone would pick up. I wouldn't hear anything,
                after 9 min's I heard a man's voice. I kept talking and talking
                saying "Mister, whoever is there, please, have mercy, think of
                your own mother, my mother is a kind person, with a good heart".
                No voice anymore. We stayed up all night and every 15 mins I
                harassed the bellboy to check up on my mother, especially around
                6am. Then my brother called and said:{" "}
              </p>
              <p
                css={css`
                  font-size: 40px;
                  line-height: 50px;
                  font-family: "Averia Serif Libre";
                `}
              >
                “I called the bellboy and she is gone.”
              </p>

              <p>
                My mother had left a package and quickly left. I couldn't
                believe it. Couldn't. I thought: oh my god we have lost her. I
                have lost my mum. She went rogue. Deliberately leaves behind our
                backs. Who knows when she will turn on her phone? Who knows what
                kind of deal she has made with some Cambodian gangster? She
                almost made one, she would have gotten so called “protection”,
                in exchange of 10.000 dollars she DOES NOT have. She almost
                agreed to this the first day after the funeral.
              </p>
              <p>
                Half an hour later, she video calls us. She says: "what's wrong,
                I am just having breakfast", "I saw some calls but I thought
                they were bad people trying to stalk me for my land", "I am
                going to fix the phone later so I won't be reachable for a few
                hours".
              </p>
              <p>
                I lied and said: 'The{" "}
                <Redacted width="120" background="#c96c6d" /> has ordered you to
                translate dad's document at a particular place today so whatever
                you have planned for today, you need to cancel it'. She
                exploded! This explosion revealed that she knew I knew she was
                lying and that she was caught. It was like watching a fifteen
                year old lie. She went back to the hotel.
              </p>
              <p>
                I lied and said she has been ordered to{" "}
                <Redacted width="320" background="#c96c6d" /> and that it was
                really important for her to go. She is sensitive to authority,
                so she was willing. <Redacted width={80} background="#c96c6d" />{" "}
                was waiting downstairs already so she wondered if she should go
                now? And I said, let me ring the{" "}
                <Redacted width={80} background="#c96c6d" /> first.{" "}
                <Redacted width={120} background="#c96c6d" />. It was about
                7.30/8am and I rang <Redacted width={60} background="#c96c6d" />
                . Someone on Facebook who used to work for the{" "}
                <Redacted background="#c96c6d" /> had recommended her.{" "}
              </p>
              <p>
                So there she went, early in the morning. I fell asleep, because
                I had been up all night. When I woke up my mother called me to
                say that when she arrived there
                <div
                  css={css`
                    float: left;
                    width: 100%;
                    background: #c96c6d;
                    height: 80px;
                    margin-bottom: 40px;
                  `}
                ></div>
              </p>
            </div>
            <div>
              <div
                css={css`
                  float: left;
                  width: 100%;
                  margin-bottom: 80px;
                  margin-top: 80px;
                  img {
                    width: 100%;
                  }
                `}
              >
                <img src={BIRDS} />
              </div>
            </div>
            <div
              css={css`
                margin-left: auto;
                margin-right: auto;
                width: 712px;
                font-family: IBM Plex Sans;
                color: white;
                font-size: 20px;
                line-height: 30px;
                @media (max-width: 980px) {
                  padding-left: 130px;
                  box-sizing: border-box;
                  width: calc(100% - 72px);
                }
                @media (max-width: 480px) {
                  padding-left: 0px;
                }
              `}
            >
              <p
                css={css`
                  :first-letter {
                    color: white;
                    float: left;
                    font-family: IM Fell Double Pica;
                    font-size: 58px;
                    line-height: 56px;
                    padding-top: 4px;
                    padding-right: 8px;
                    padding-left: 3px;
                  }
                `}
              >
                Since then - Tuesday to be exact - things have calmed down a
                bit. It was a happy accident that when I{" "}
                <Redacted width="300" background="#c96c6d" /> answer all her
                questions and <Redacted width="200" background="#c96c6d" /> lady
                who takes care of her. Her tuk tuk driver is super reliable and
                kindhearted. When she makes a payment and forgets the change, he
                jumps in. To her, it seems I had arranged all of this in a
                matter of days, but I don't know how it all came together.
              </p>
              <p>
                I changed my tone and stopped calling her mum with every
                pronoun. I said 'you' instead and started speaking Dutch instead
                of Khmer. She changed her tone towards me. I feel like she
                emancipated herself from the role of the mother. Words are a
                funny thing.
              </p>
              <p>
                I thought to myself that I need to talk to her about mourning. I
                focused on the perspective of a woman losing their life partner
                and starting talking about how I am sad for her to loseher
                husband. I acknowledged she had been by his side since she got
                married to him at age seventeen. I spoke to her about the fact
                that one day she woke up and he was gone. I said I imagine it
                must be scary and shocking. "Yes" she replied. "Yes". Then she
                wrote "He happy me". She meant he always made her smile.
              </p>
              <p>
                She opened up about my father visiting her. He said she
                shouldn't go back to the land. It was dangerous, he said. She
                asked me to pray and ask for forgiveness for my mistakes to him,
                and vice versa. I told her I would and that I have been
                fortunate enough to have seen my dad twice since his passing.
              </p>
              <p>In both cases, he was smiling and laughing. </p>
              <img src={SUNNY}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LetterThree;
