/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Redacted from "./Redacted";
import VUTHY from "./assets/images/vuthy.png";
import MATI from "./assets/images/mati.png";
import OFFERING from "./assets/images/offering.png";
import PANOTE from "./assets/images/papake.png";
import FLOW from "./assets/images/flow.png";
import BGONE from "./assets/images/background-letter-1.png";

function LetterOne() {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        height: 100%;
        background: #ba4746;
        position: relative;
        p {
          margin-bottom: 40px;
        }
        ::before {
          background: url(${BGONE});
          background-size: cover;
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0.4;
          z-index: 0;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: ${window.innerHeight}px;
        `}
      />
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          width: 712px;
          font-family: IBM Plex Sans;
          @media (max-width: 980px) {
            padding-left: 130px;
            box-sizing: border-box;
            width: calc(100% - 72px);
          }
          @media (max-width: 480px) {
            padding-left: 0px;
          }
        `}
      >
        <div
          css={css`
            float: left;
            width: 100%;
            margin-top: 80px;
            text-align: right;
            font-style: italic;
            font-size: 20px;
            line-height: 30px;
          `}
        >
          Wednesday, 27 Jan 2021
        </div>
        <div
          css={css`
            float: left;
            width: 100%;
            margin-top: 80px;
            font-size: 25px;
            line-height: 35px;
            font-family: Averia Serif Libre;
          `}
        >
          Dear <Redacted height="22" />,
          <p>
            I am writing to tell you that my father passed away this Monday
            afternoon, an hour after our jitsi meeting. I am going to take the
            liberty to bypass this as a formal statement and tell you what the
            situation is and what my last 48 hours have been like.
          </p>
        </div>

        <div
          css={css`
            float: left;
            width: 100%;
            font-size: 20px;
            line-height: 30px;
            font-family: IBM Plex Sans;
          `}
        >
          <p>
            My parents live on a self built farm in a remote area of Cambodia
            and my father's passing is most likely the result of a sickness
            which came up over the last few weeks. There was no autopsy made so
            I don't want to speculate too much on it. Over the last few weeks,
            my father refused to speak to me over the phone. After intense
            introspection, I deciphered it meant he was fighting for his life. I
            respected his decision to battle in solitude and accepted any
            possible outcome since then.
          </p>
          <p>
            Even though he was ill, it was still unexpected. My mum found him
            dead in his usual resting spot and immediately initiated a Khmer
            Buddhist ceremony (which includes three day heavy chanting, rituals
            and a cremation, with its cremation site made on location). Me and
            my brother witnessed the entire ceremony of our father's cremation
            through a shitty connection over Facebook video messenger until
            literally this morning at 9am.
          </p>
          <p>
            During the ceremony our mother simply turned on the camera and
            started walking around, mumbling and crying to herself, feeding the
            animals, responding to local villagers who attended the festivities
            and asked her what happened, where her family is, where her children
            are. I heard her repeatedly lament how he didn't say bye to her and
            how her children are overseas and cannot come because of corona.
          </p>
          <p>
            I was with her on Facebook video messenger every other hour. When we
            weren’t calling, it was because she had to recharge her phone
            battery or I was taking naps.
          </p>
          <p>
            An hour before the cremation, I saw her exhausted and covered in
            cold sweat. She hardly spoke to me. She couldn't hear me because of
            the noise around her, the chants and the singing which had been
            going on for three days. When she did hear me, she ordered me to do
            ceremonies for my father to show that we, as his children, are sorry
            we cannot be there.
          </p>
          <div
            css={css`
              float: left;
              width: 100%;
              img {
                width: 100%;
                display: block;
                margin-left: auto;
                margin-right: auto;
                max-width: 712px;
                margin-top: 40px;
                margin-bottom: 40px;
              }
            `}
          >
            <img src={OFFERING} />
          </div>
          <p>
            I saw my father's dead body and face twice. I saw the flames that
            burnt his body. I saw how they picked a few bones after the
            cremation and cleant them. I saw it all through a blurry portait
            image.
          </p>
          <p>
            My father is my soulmate. When he died, I was very calm. I felt him
            super close to me, as he had been so distant before. Not long after
            he passed, I remember saying this:
          </p>
          <p
            css={css`
              font-family: "Patua One";
              font-size: 30px;
              line-height: 40px;
            `}
          >
            I have always seen my father as part of the past and the future. He
            is a spirit that was not limited to his earthly body. He is the root
            of my essence; he is a culture in itself, an attitude, a way of
            being. He is now the sun and the glowing moon at night. Death is a
            positive and beautiful thing. I respect him, I am not mad, I trust
            his decision to go.
          </p>
          <p>
            I have always felt that me and my father are one. Everything I hate
            about myself, I hate about him. Everything I love about him, I love
            about myself.
          </p>
          <p>
            He is cheeky, charming, good hearted, obnoxious, creative,
            idealistic and funny. He lived through the Khmer Rouge regime, fled
            to the border and hustled in the refugee camps, made a living and
            raised us in Holland, returned to Cambodia with a grandiose plan and
            died.
          </p>
          <p>
            My parents live a solitary life in Cambodia and the people who came
            to the ceremony are essentially strangers. My father's closest and
            life long friend <Redacted /> moved back to Holland a few years
            before, selling his plot of land to
            <div
              css={css`
                float: left;
                width: 100%;
                background: black;
                height: 100px;
                margin-bottom: 30px;
              `}
            ></div>
          </p>
          <p>
            The worry of what will become of my mother was the second thing that
            struck me when I heard of my fathers passing. I remember whispering
            to <Redacted width="80"></Redacted> on my way to my brother's that:
          </p>
          <p
            css={css`
              font-size: 50px;
              line-height: 60px;
              font-family: "Averia Serif Libre";
            `}
          >
            “We might have to go Cambodia very soon and everything we know will
            change.”
          </p>

          <p>The ideas are: </p>
          <p>
            1) she leaves immediately, though the land is often then regarded as
            no man's land and consequently she could lose so much she has worked
            for (they sold our house in Holland to buy the land in Cambodia);
          </p>
          <p>
            2) she stays but she will be at risk as a woman and the process can
            take months (and my brother accompanying her will not be risk free
            considering covid-19 among other issues).
          </p>
          <p>
            Both ideas are problematic on so many levels (there are so many
            questions) and it has been hard trying to sift through all the
            scenarios whilst being mindful of what my mother is going through,
            as with ourselves mourning, as with covid-19 making everything ten
            times more difficult, as with my father being a spirit since the
            three days. To gain information about what is best seems often
            tucked away behind a meaningless braggadocious of 'we know people',
            'I know someone' from someone in the family.
          </p>
          <p>
            Though I have been calling for advice with friends in Cambodia and
            elsewhere. Trying to get information on: how to get to Cambodia in
            covid-19 times (as far as we know it’s a two week mandatory hotel
            quarantine, 2k deposit you need to lay down), how to sell land in
            remote Cambodia, what actions are best to take in this situation,
            and actually have had concrete advice and genuine offers for help...
            These have been from Khmers I have contacted and became familiar
            with in the last decade.
          </p>
          <p>
            It was from the time in my life when I cared to some extend for our
            community. Even when I found being Khmer was difficult. I see the
            help and the advice as part of my karma returning back to me, from
            being kind and helpful to strangers when I could.
          </p>
          <p>
            My mother is offline and my brother and I speculate she is probably
            clearing her head. This morning she told my brother she wanted to
            come to Holland immediately. Then a split second later, she changed
            her mind and decided to stay and sell the land. I try not to worry
            about her level of decision making, and hope that she is well
            informed about what goes on and what is possible in both situations.
            I hope she understands that we are doing what we can to help her in
            this process.
          </p>
          <p>
            It is a custom to offer food and drink and pray every morning and
            evening for the next seven days as part of one of the phases for him
            to transition properly. I would like it if you could pray for him
            too, in your own way, whenever you can. His name is Tol Vuthy.
          </p>
          <p>Thank you.</p>
          <img src={FLOW} width="4.5%"></img>
        </div>
      </div>
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          width: 712px;
          height: 2000px;
          font-family: IBM Plex Sans;
          @media (max-width: 980px) {
            width: calc(100% - 72px);
          }
        `}
      ></div>
      <img width="1%" src={PANOTE}></img>
      <div
        css={css`
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 746px;
          @media (max-width: 980px) {
            width: calc(100% - 72px);
          }
        `}
      >
        <div
          css={css`
            float: left;
            margin-bottom: 60px;
          `}
        >
          <div
            css={css`
              float: left;
              width: calc(50% - 15px);
              img {
                max-width: 100%;
                margin-top: 40px;
                margin-bottom: 20px;
              }
            `}
          >
            <img src={VUTHY} />
            <div
              css={css`
                display: block;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                font-size: 15px;
                color: black;
                font-family: "Averia Serif Libre";
              `}
            >
              <b>Boston, Massachusetts</b>
            </div>
          </div>
          <div
            css={css`
              float: right;
              width: calc(50% - 15px);
              img {
                max-width: 100%;
                margin-top: 40px;
                margin-bottom: 20px;
              }
            `}
          >
            <img src={MATI} />
            <div
              css={css`
                display: block;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                font-size: 15px;
                color: black;
                font-family: "Averia Serif Libre";
              `}
            >
              <b>Port Louis, Mauritius</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LetterOne;
