/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import HEAVEN from "./assets/images/heaven.png";

function LetterFour() {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
        height: 100%;
        background: black;
        img {
          width: 100%;
          display: block;
          margin-left: auto;
          margin-right: auto;
          max-width: 649px;
          margin-top: 60px;
          margin-bottom: 40px;
        `}
    >
      <img src={HEAVEN} />
      <div
        css={css`
          margin-left: auto;
          margin-right: auto;
          width: 712px;
          font-family: Averia Serif Libre;
          font-size: 18px;
          font-height: 20px;
          color: white;
          text-align: center;
          margin-bottom: 80px;
          @media (max-width: 980px) {
            width: calc(100% - 72px);
          }
        `}
      >
        What me and my dad had in common<br></br>
        what no one around us seemed to understand<br></br>
        is that we were at our happiest<br></br>
        when we were creating.<br></br>
      </div>
    </div>
  );
}

export default LetterFour;
