/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import DEVILSPLAYGROUND from "./assets/images/DEVILS_PLAYGROUND.png";
import GHETTOSMIND from "./assets/images/GHETTOS_MIND.png";

function Minds() {
  const windowHeight = window.innerHeight;

  return (
    <div>
      <div
        css={css`
          float: left;
          width: 100%;
          background: black;
          height: ${windowHeight}px;
          img {
            width: 100%;
            display: block;
            margin-left: auto;
            margin-right: auto;
            max-width: 756px;
            margin-top: 40px;
          }
          @media (max-width: 980px) {
            width: calc(100% - 108px);
            margin-left: 54px;
          }
          @media (max-width: 480px) {
            width: 100%;
            margin-left: 0px;
          }
        `}
      >
        <img src={GHETTOSMIND} />
        <img
          src={DEVILSPLAYGROUND}
          css={css`
            padding-bottom: 40px;
          `}
        />
      </div>
    </div>
  );
}

export default Minds;
