/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import { useEffect, useState } from "react";
import Redacted from "./Redacted";
import PAPSKI from "./assets/images/pa.png";
import PAPSKI2 from "./assets/images/pa2.png";

import HEAVEN from "./assets/images/heaven.png";

import ROOFTOP from "./assets/images/rooftop.png";
import BIRDS from "./assets/images/birds.png";
import ZONDER from "./assets/images/zonder.png";
import SUNNY from "./assets/images/sunny.png";

import MISC from "./assets/images/pages.png";
import LETTERS from "./assets/images/letters.png";
import LOVE from "./assets/images/love.png";
import BG from "./assets/images/background-letter-2.png";

import VUTHY from "./assets/images/vuthy.png";
import MATI from "./assets/images/mati.png";
import OFFERING from "./assets/images/offering.png";
import PANOTE from "./assets/images/papake.png";
import FLOW from "./assets/images/flow.png";
import BGONE from "./assets/images/background-letter-1.png";

import WHEEL from "./assets/images/wheeloflife.gif";

function Splash({ setAccess }) {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const IMAGES = [
      HEAVEN,
      ROOFTOP,
      BIRDS,
      ZONDER,
      SUNNY,
      MISC,
      LETTERS,
      LOVE,
      BG,
      VUTHY,
      MATI,
      OFFERING,
      PANOTE,
      FLOW,
      BGONE,
    ];

    let count = 0;

    IMAGES.forEach((url) => {
      var img = new Image();
      img.onload = function () {
        count = count + 1;
        if (count === IMAGES.length) {
          setLoaded(true);
        }
      };
      img.src = url;
    });
  }, []);

  useEffect(() => {
    if (
      password === "sathu3" ||
      password === "rdam010" ||
      password === "publi2"
    ) {
      setLoading("INPROGRESS");
    }
  }, [password]);

  useEffect(() => {
    if (loading === "INPROGRESS") {
      setTimeout(() => {
        setLoading("DONE");
        if (loaded) {
          setAccess(true);
        }
      }, 2000);
    }
  }, [loading]);

  useEffect(() => {
    const hasAccess = loading === "INPROGRESS" || loading === "DONE";
    if (loaded && hasAccess) {
      setAccess(true);
    }
  }, [loaded]);

  return (
    <div
      css={css`
        float: left;
        width: 100%;
        font-family: Courier new;
        font-size: 18.75px;
        font-weight: bold;
        user-select: none;
        p {
          margin-bottom: 30px;
        }
        background: ${loading ? "black" : "#fffefd"};
        transition: background 1s;
      `}
    >
      <div
        css={css`
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          opacity: ${loading ? 1 : 0};
          transition: opacity 1s;
          z-index: ${loading ? 1 : -1};
        `}
      >
        <img src={WHEEL} height="120" />
      </div>
      <div
        css={css`
          margin-top: 80px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        `}
      >
        <div
          css={css`
            position: absolute;
            right: 30%;
            top: 220px;
            @media (max-width: 980px) {
              right: 12px;
              img {
                width: 50px;
              }
            }
          `}
        >
          {!loading && <img src={PAPSKI}></img>}
        </div>
        <div
          css={css`
            position: absolute;
            left: calc(50% - 450px);
            top: 700px;
            @media (max-width: 980px) {
              left: 0px;
            }
          `}
        >
          {!loading && <img src={PAPSKI2}></img>}
        </div>{" "}
        <h1
          css={css`
            font-family: IM Fell Double Pica;
            font-size: 30px;
            font-weight: 1000;
            letter-spacing: 0px;
            margin-bottom: 0px;
          `}
        >
          SATHU.STUDIO
        </h1>
        27 JAN 2021 - 12 FEB{" "}
        <span
          css={css`
            letter-spacing: -2.5px;
          `}
        >
          2021
        </span>
        <br />
        <br />
        <br />
        ENTER PASSWORD:
        <br />
        {loading ? (
          <div>LOADING...</div>
        ) : (
          <div>
            <input
              css={css`
                border: 2px solid black;
                padding: 2.5px;
                font-family: Courier New;
                font-size: 15.75;
                background: #fffefd;
              `}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        )}
      </div>
      <div
        css={css`
          margin-top: 80px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 800px;
          color: ${loading ? "black" : "#101010"};
          a,
          a:visited,
          a:active {
            color: ${loading ? "black" : "#101010"};
          }
          @media (max-width: 980px) {
            width: calc(100% - 72px);
          }
        `}
      >
        PREQUEL SENT: 14 JAN{" "}
        <span
          css={css`
            letter-spacing: -2.5px;
          `}
        >
          2021
        </span>
        <br />
        -----------------
        <br />
        <br />
        <div
          css={css`
            transform: rotate(0.3deg);
          `}
        >
          Dear <Redacted width="120"></Redacted>,<br />
          <p>
            As you know by now I don't like to be a broken record talking about
            my Khmer heritage, 1) because to explain 'what is so Cambodian'
            means centering the characteristics of the West and then mirroring
            Cambodianness to that, which is impossible and reductive to both
            'ways of beings' and especially because Khmerness is not lived by
            you nor 99% of anyone in the Netherlands, but by me, so there's an
            imbalance at hand, - and 2) I feel it essentializes both ways of
            being and perhaps assumes that I should (not) understand, embody or
            shapeshift between multiple 'ness'-es, because if we're all the
            same, what's the point of explaining this difference?
          </p>
          <p>
            Another, less complicated, more pitiful reason for me to withhold
            from being Cambodia's ambassador to every non-Cambodian is that the
            country was complicated by the West's interventions such as becoming
            a Protectorate by the French for seventy odd years and being part of{" "}
            <a href="https://youtu.be/bmKxhbeE8-8">
              Nixon's secret bombing campaign
            </a>
            , making Cambodia the most bombed country at the time. Cambodia was
            neutral - and so as a daughter of the diaspora I am resentful.
          </p>
          <p>
            At the heart of our story seems to be 'our auto-genocide', a nearly
            four year communist rule, a memory haunted by every member of my
            community of anyone over 50, and a fascination by the West as a
            topic to revisit over and over (most likely because they feel 'they
            had nothing to do with it' which is everything but true) without any
            effort to spiritually guide those who have been through it, those
            who are trying to live their life in Post War Cambodia or the Post
            Colonial West, those who never chose to be the pitiful and exotified
            subjects of Western intellectuals and artfilm lovers. I believe that
            these complicated revisits are a part of a tradition rooted in the
            West and Cambodians themselves find them rather unhelpful and say
            they cause bad spirits to re-arise, but that's a different story.
          </p>
          <p>
            As much as I do not like to mention the Khmer Rouge, it is relevant
            to this effort of explicating the difference from what no one in the
            West seems to understand: the difference between the journey of a{" "}
            <span
              css={css`
                letter-spacing: -1.5px;
              `}
            >
              Khmer
            </span>
            , a Thai or a Vietnamese to the West, so I do invite you to Google
            it, but I would like to emphasize that every member of my community
            has a different stance on these horrible four years which still
            divides us across the diasporas and in the homeland to this day.
          </p>
          <p>
            I am guessing that the complications of those years (and the years
            before and the years that followed) make it so that it's a hard
            story to tell, if we can even talk about a singular story, and so my
            parents and every other member of my community decided to tell none.
            I have spent the last ten years living at the margins of having been
            told this story, telling this story, trying to understand this story
            and ignoring this story, to arrive at the plane of where I was
            before I knew of any story: I am me, innit, I am here and nothing
            changes that.
          </p>
          <p>
            From a sociological point of view, why there's so little (media) on
            Cambodianness is a numbers game. I can explain to you that the
            largest{" "}
            <span
              css={css`
                letter-spacing: -1.5px;
              `}
            >
              Khmer
            </span>{" "}
            community which resides outside of Cambodia is located in{" "}
            <a href="http://www.pbs.org/wgbh/america-by-the-numbers/episodes/episode-106/">
              Long Beach
            </a>
            , followed by Paris, Sydney, but still amounts to very little
            compared to our South East Asian counterparts. The only reason why
            these numbers are relevant is, what I have learnt in my ten years of
            living and understanding the diasporas (in Sydney, Berlin, Antwerp,
            London), because numbers/occupation/class impact the community's
            effort to survive, and what's at the centre of my identity is the
            ongoing conversation of what it means to migrate and (what the Dutch
            love to focus on so much = ) to integrate. Cambodians have been
            invited here in the Netherlands as refugees in the eighties (after
            active lobbying) and a whole 580 of us came here (compared to 16.000
            Vietnamese).
          </p>
          <p
            css={css`
              color: black;
            `}
          >
            My background and curiosity for the survival and visibility of my
            own kind has made me sympathetic to anyone with a struggle. It seems
            that is the fate of many migrants' children to side with other brown
            folk. I am in debt to any author and artist who has created anything
            I can revisit to help me understand how to move on: move on from the
            trauma, from the illnesses in our community and how to stop
            navigating this world blind as we did. Unwillingly the role of the
            teacher is therefore loaded for me, and at the moment I find solace
            in spirituality, and previously togetherness. I am also particularly
            sensitive to nuances within migrant's conditions.
          </p>
          <p>
            To this day I wish the{" "}
            <span
              css={css`
                letter-spacing: -1.5px;
              `}
            >
              Khmer
            </span>{" "}
            story has a happy ending but my mind is fogged with worry. My
            father, the first of refugees to have obtained a Bachelors (in
            technical engineering) worked for one company in my hometown
            (Roosendaal, Noord-Brabant, South of the Netherlands) for years but
            in the crisis of 2009's he was laid off and because of sheer Dutch
            racism (and ageism) he wasn't able to find work at his capacity. So
            my parents decided to move and start a (shiitake mushroom) farm in
            North Western Cambodia and it has been five years of (in the words
            of my mother) hard work, pride and regret. Since the last three
            weeks my father has fallen ill, corona has closed the borders to
            Thailand making business particularly hard, as is access to adequate
            hospitals, and the nightmares won't stop. So I apologise if I was a
            bit harsh yesterday. Focusing on positivity has been particularly
            challenging this week.
          </p>
          <p>
            To end this emotional email, I would like to invite you to watch
            this film: '
            <a href="https://youtu.be/2NUeOvikEic">Return to Khao-I-Dang</a>' by
            a late Khmer-Australian filmmaker.
            <br />
            <br />
            <div
              css={css`
                float: left;
                width: 100%;
                background: black;
                height: 80px;
                margin-bottom: 30px;
              `}
            ></div>
            <a href="https://www.facebook.com/khaoIDangLC/videos/bamboo-city-video-of-unhcr/657647684615578/">
              Khao-I-Dang
            </a>{" "}
            is the UN refugee camp (the largest 'city' in the region at the
            time) where my parents spent four years, my mother as a teenager and
            my father as a guard to her family: where they met, got married and
            my brother was born. There they applied for both Australia and the
            Netherlands. They accepted their invitation to come here in 1988.
            Here I was born.
          </p>
          <p>
            I wrote this email with a lot of{" "}
            <span
              css={css`
                letter-spacing: -1.5px;
              `}
            >
              love.
            </span>
          </p>
        </div>
      </div>
      <div
        css={css`
          margin-top: 80px;
          margin-bottom: 80px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          width: 932px;
          text-align: center;
          a,
          a:visited,
          a:active {
            color: black;
          }
          @media (max-width: 980px) {
            width: calc(100% - 72px);
          }
        `}
      >
        « EMAIL:{" "}
        <a href="mailto:samboleaptol@gmail.com">samboleaptol@gmail.com</a> FOR
        PASSWORD ACCESS »
      </div>
    </div>
  );
}

export default Splash;
