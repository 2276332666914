/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

function Redacted({ width, height, background }) {
  return (
    <span
      css={css`
        display: inline-block;
        width: ${width ? width + "px" : "80px"};
        height: ${height ? height + "px" : "20px"};
        background: ${background ? background : "black"};
      `}
    ></span>
  );
}

export default Redacted;
