/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from "@emotion/react";

function Content({ children }) {
  return (
    <div
      css={css`
        float: left;
        width: 100%;
      `}
    >
      {children}
    </div>
  );
}

export default Content;
